<template>
  <v-dialog
    v-model="visible"
    class="person-modal"
    scrollable
    max-width="750"
  >
    <v-card
      v-if="action === 'delete'"
      class="pa-6"
    >
      <v-card-title primary-title>
        <div class="headline darkestblue--text">
          Are you sure?
        </div>
        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close(action)"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text>
        <div class="coolgray--text">
          <v-row>
            <v-col class="subtitle-2">
              ID
            </v-col>
            <v-col class="body-1">
              {{ entry.personId }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              First Name
            </v-col>
            <v-col class="body-1">
              {{ entry.personNameFirst }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="subtitle-2">
              Last Name
            </v-col>
            <v-col class="body-1">
              {{ entry.personNameLast }}
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          color="coolgray"
          @click.stop="cancelAction"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click.stop="submitPersonAction"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="['create', 'update'].includes(action)"
      class="pa-4"
    >
      <v-card-title>
        <span>
          {{ action === 'create' ? 'Add' : 'Edit' }} Person
        </span>

        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close(action)"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="personForm"
          v-model="isValidPersonForm"
        >
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <h4 class="pl-3">
                    Contact
                  </h4>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="entry.personNameFirst"
                      label="First Name"
                      name="personNameFirst"
                      required
                      :rules="rulesRequired"
                      type="textual"
                    />

                    <v-text-field
                      v-model="entry.personNameLast"
                      label="Last Name"
                      name="personNameLast"
                      required
                      :rules="rulesRequired"
                      type="textual"
                    />

                    <v-text-field
                      v-model="entry.email"
                      label="Email"
                      name="email"
                      required
                      :rules="rulesEmail"
                      type="textual"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <h4 class="pl-3">
                        General Access
                      </h4>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="entry.isInternal"
                          label="Internal"
                          name="active"
                        />

                        <v-checkbox
                          v-model="entry.access.active"
                          label="iAdOps Access"
                          name="active"
                        />

                        <v-menu
                          ref="entryDate"
                          v-model="entryDateMenu"
                          :return-value="entry.access.expires"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="entryDateText"
                              label="Expires"
                              readonly
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="entry.access.expires"
                            no-title
                            scrollable
                            @input="$refs.entryDate.save(entry.access.expires)"
                          />
                        </v-menu>

                        <v-autocomplete
                          v-model="entry.access.level"
                          autocomplete="off"
                          clearable
                          :items="levels"
                          label="Level"
                          name="level"
                        />

                        <v-autocomplete
                          v-model="entry.access.role"
                          autocomplete="off"
                          clearable
                          :items="roles"
                          label="Role"
                          name="role"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <h4 class="pl-3">
                        Permissions & Settings
                      </h4>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="entry.access.permissions.alias"
                          label="Alias Mode"
                          name="permissionsAlias"
                        />

                        <v-checkbox
                          v-model="entry.access.permissions.bulk"
                          label="Bulk Actions"
                          name="permissionsBulk"
                        />

                        <v-checkbox
                          v-model="entry.hasCustomReportsActive"
                          label="Custom Reports"
                          name="permissionsBulk"
                        />

                        <v-checkbox
                          v-model="entry.access.permissions.dev"
                          label="Dev"
                          name="permissionsDev"
                        />

                        <v-checkbox
                          v-model="entry.access.permissions.demo"
                          label="Demo"
                          name="permissionsDemo"
                        />
                      </v-col>

                      <v-col>
                        <v-checkbox
                          v-model="entry.access.permissions.pacing"
                          label="Pacing"
                          name="permissionsPacing"
                        />

                        <v-checkbox
                          v-model="entry.access.permissions.discrepancy"
                          label="Discrepancy"
                          name="permissionsDiscrepancy"
                        />

                        <v-checkbox
                          v-model="entry.access.permissions.programmatic"
                          label="Programmatic"
                          name="permissionsProgrammatic"
                        />

                        <v-checkbox
                          v-model="entry.access.permissions.settings"
                          label="Advanced Settings"
                          name="permissionsSettings"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="entry.access.permissions.alias"
                    >
                      <v-col>
                        <v-row>
                          <h4 class="pl-3">
                            Alias
                          </h4>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-checkbox
                              v-model="entry.settings.alias.active"
                              label="Alias Mode On"
                              name="settings.alias.active"
                              class="pr-3 mt-0"
                              dense
                              x-small
                            >
                              <template v-slot:label>
                                <span class="body-2 font-weight-medium">
                                  Alias Mode On
                                </span>
                              </template>
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row
                          class="pl-8 mt-0"
                        >
                          <v-col>
                            <v-autocomplete
                              v-model="entry.settings.alias.email"
                              autocomplete="off"
                              :items="internals"
                              item-value="email"
                              label="Alias Person"
                              name="settings.alias.email"
                              clearable
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="entry.hasCustomReportsActive"
                    >
                      <v-col>
                        <v-row>
                          <h4 class="pl-3">
                            Custom Reports
                          </h4>
                        </v-row>

                        <v-row
                          class="pl-8"
                        >
                          <v-col>
                            <v-autocomplete
                              v-model="entry.access.permissions.customReports"
                              autocomplete="off"
                              clearable
                              dense
                              :items="customReportOptions"
                              label="Custom Reports"
                              name="customReports"
                              :required="entry.hasCustomReportsActive"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="entry.access.permissions.settings"
                    >
                      <v-col>
                        <v-row>
                          <h4 class="pl-3">
                            Advanced Settings
                          </h4>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col>
                                <v-btn
                                  small
                                  text
                                  @click="openSettingFormCreate"
                                >
                                  <span>
                                    Add Setting
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-row
                              v-if="showSettingForm"
                            >
                              <v-col class="px-9 col-9">
                                <v-form
                                  ref="settingForm"
                                  v-model="isValidSettingForm"
                                >
                                  <v-row>
                                    <v-col class="py-0">
                                      <v-text-field
                                        v-model="entrySetting.name"
                                        dense
                                        label="Name"
                                        name="settingName"
                                        required
                                        :rules="rulesRequired"
                                        type="textual"
                                      />
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col class="py-0">
                                      <v-autocomplete
                                        v-model="entrySettingTeams"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        :items="teams"
                                        label="Teams"
                                        name="settingTeams"
                                        multiple
                                      />
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col class="py-0">
                                      <v-autocomplete
                                        v-model="entrySetting.reports"
                                        autocomplete="off"
                                        clearable
                                        dense
                                        :items="settingReportOptions"
                                        label="Reports"
                                        name="settingReports"
                                        multiple
                                      />
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col>
                                      <v-row>
                                        <v-col>
                                          <span>
                                            Parameters
                                          </span>
                                        </v-col>
                                      </v-row>

                                      <v-row>
                                        <v-col>
                                          <v-autocomplete
                                            v-model="entrySettingParameter.key"
                                            autocomplete="off"
                                            clearable
                                            dense
                                            :items="allParamOptions"
                                            label="Property Name"
                                            name="settingParameters"
                                            multiple
                                          />

                                          <v-autocomplete
                                            v-model="entrySettingParameter.operator"
                                            autocomplete="off"
                                            clearable
                                            dense
                                            :items="allOperatorOptions"
                                            label="Operator"
                                            name="settingOperator"
                                          />

                                          <v-text-field
                                            v-model="entrySettingParameter.value"
                                            dense
                                            label="Value"
                                            name="settingValue"
                                            required
                                            :rules="rulesRequired"
                                            type="textual"
                                          />

                                          <v-btn
                                            color="primary"
                                            icon
                                            x-small
                                            @click="saveSettingParameters"
                                          >
                                            <v-icon>plus</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>

                                  <v-row
                                    v-for="(params, paramName) in entrySetting.parameters"
                                    :key="paramName"
                                  >
                                    <v-col class="mb-6">
                                      <v-row
                                        v-for="(columnFilters, columnKey) in params"
                                        :key="columnKey"
                                      >
                                        <v-col>
                                          <v-row
                                            v-for="(operatorAndValue, valueIndex) in columnFilters"
                                            :key="valueIndex"
                                          >
                                            <v-col class="col-3 py-0">
                                              <v-autocomplete
                                                v-model="entrySetting.parameters[columnKey][valueIndex]"
                                                autocomplete="off"
                                                clearable
                                                dense
                                                :items="allOperatorOptions"
                                                label="Operator"
                                                name="settingOperator"
                                              />
                                            </v-col>

                                            <v-col class="py-0 font-weight-medium">
                                              <v-text-field
                                                v-model="entrySetting.parameters[columnKey][valueIndex]"
                                                dense
                                                label="Value"
                                                name="settingValue"
                                                required
                                                :rules="rulesRequired"
                                                type="textual"
                                              />
                                            </v-col>

                                            <v-col>
                                              <v-btn
                                                icon
                                                x-small
                                                @click="deleteSettingParameters(columnKey, valueIndex)"
                                              >
                                                <v-icon>trash</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col class="py-0">
                                      <v-checkbox
                                        v-model="isEntrySettingPrefilter"
                                        dense
                                        label="Use as Prefilter"
                                        name="prefilter"
                                      />
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col class="justify-end">
                                      <v-btn
                                        text
                                        color="toasted"
                                        @click.stop="resetSettingForm"
                                      >
                                        Reset
                                      </v-btn>

                                      <v-btn
                                        text
                                        color="coolgray"
                                        @click.stop="cancelSettingForm"
                                      >
                                        Cancel
                                      </v-btn>

                                      <v-btn
                                        color="primary"
                                        @click.stop="saveSettingForm"
                                      >
                                        OK
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </v-row>

                            <v-row class="px-3 pb-6 mt-0 elevation-0">
                              <v-col
                                v-if="advancedSettings && advancedSettings.length"
                                class="body-2 pb-0"
                              >
                                <v-list>
                                  <v-list-group
                                    v-for="(setting, settingIndex) in advancedSettings"
                                    :key="settingIndex"
                                    v-model="setting.active"
                                    :prepend-icon="setting.action"
                                    no-action
                                  >
                                    <template v-slot:activator>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="body-2 font-weight-medium"
                                          v-text="setting.name"
                                        />
                                      </v-list-item-content>
                                    </template>

                                    <v-list-item v-if="setting.parameters">
                                      <v-col>
                                        <v-row>
                                          <v-col>
                                            <v-row>
                                              <v-col>
                                                <div class="font-weight-bold">
                                                  Name
                                                </div>
                                                <div>
                                                  {{ setting.name || 'Unnamed' }}
                                                </div>
                                              </v-col>
                                            </v-row>

                                            <v-row
                                              v-if="setting.network"
                                            >
                                              <v-col>
                                                <div class="font-weight-bold">
                                                  Networks
                                                </div>
                                                <div
                                                  v-for="(network, networkIndex) in setting.network"
                                                  :key="networkIndex"
                                                >
                                                  {{ getOrgAndTeamName(network) }}
                                                </div>
                                              </v-col>
                                            </v-row>

                                            <v-row>
                                              <v-col>
                                                <div class="font-weight-bold">
                                                  Reports
                                                </div>
                                                <div>
                                                  {{ setting.reports.join(', ') }}
                                                </div>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>

                                        <v-row>
                                          <v-col class="font-weight-bold">
                                            <v-row class="mt-1">
                                              <v-col class="col-4 py-0">
                                                Property
                                              </v-col>
                                              <v-col class="col-2 py-0">
                                                Operator
                                              </v-col>
                                              <v-col class="py-0">
                                                Value
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>

                                        <v-row
                                          v-for="(params, paramName) in setting.parameters"
                                          :key="paramName"
                                        >
                                          <v-col class="col-4 pt-0">
                                            {{ paramName }}
                                          </v-col>

                                          <v-col class="mb-6">
                                            <v-row
                                              v-for="(param, paramIndex) in params"
                                              :key="paramIndex"
                                            >
                                              <v-col class="col-3 py-0">
                                                {{ param.operator }}
                                              </v-col>
                                              <v-col class="py-0 font-weight-medium">
                                                {{ param.value }}
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-list-item>

                                    <v-list-item v-else>
                                      {{ JSON.stringify(setting) }}
                                    </v-list-item>
                                  </v-list-group>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <h4 class="pl-3">
                        Networks
                      </h4>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="currentNetworks"
                          autocomplete="off"
                          :items="organizations"
                          label="Organizations"
                          name="organizations"
                          clearable
                          multiple
                          return-object
                        />

                        <v-autocomplete
                          v-if="currentNetworks.length"
                          v-model="currentTeams"
                          autocomplete="off"
                          :items="optionsTeams"
                          label="Teams"
                          name="teams"
                          item-text="text"
                          item-value="uniqueId"
                          clearable
                          multiple
                          return-object
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row
                  v-if="entry.access.role === 'internal'"
                >
                  <v-col>
                    <v-row>
                      <h4 class="pl-3">
                        Associations
                      </h4>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="entry.managerPersonId"
                          autocomplete="off"
                          :items="internals"
                          item-value="personId"
                          label="Manager"
                          name="managerPersonId"
                          clearable
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    color="coolgray"
                    @click.stop="cancelAction"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click.stop="submitPersonAction"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'underscore'
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'
import operators from 'src/utils/operators'

const { mapGetters, mapState } = createNamespacedHelpers('internal')
const { mapGetters: mapToolsGetters } = createNamespacedHelpers('tools')

export default {
  name: 'PersonModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    isValidPersonForm: true,
    selectedNetworks: [],
    selectedTeams: [],
    entryDateMenu: false,
    entrySettingParameter: { key: 'advertiserName', operator: 'eq', value: undefined },

    isEntrySettingPrefilter: false,
    isValidSettingForm: true,

    entrySetting: {},
    selectedSetting: {},

    settingReportOptions: [
      { text: 'Discrepancy', value: 'discrepancy' },
      { text: 'Pacing', value: 'pacing' },
    ],

    showSettingForm: false,
  }),
  computed: {
    ...mapToolsGetters([
      'customReportOptions',
      'organizations',
      'persons',
      'teams',
    ]),
    ...mapGetters([
      'errorMessage',
      'internals',
      'successMessage',
    ]),
    ...mapState({
      action: (state) => state.action,
      didLoadPersonFail: (state) => state.didLoadPersonFail,
      entry: (state) => state.entry,
      isLoadingPerson: (state) => state.isLoadingPerson,
      isPersonModalVisible: (state) => state.isPersonModalVisible,
      levels: (state) => state.levels,
      roles: (state) => state.roles,
      selected: (state) => state.selected,
    }),
    visible: {
      get() {
        return this.isPersonModalVisible
      },
      set(value) {
        this.$store.commit('internal/isPersonModalVisible', value)
      },
    },
    viewPerson() {
      const {
        persons,
        didLoadPersonFail,
        isLoadingPerson,
      } = this

      return isLoadingPerson === false
        && didLoadPersonFail === false
        && persons.length > 0
    },
    noPersonData() {
      const {
        persons,
        isLoadingPerson,
        didLoadPersonFail,
      } = this
      const requestComplete = isLoadingPerson === false
      const requestSucceeded = didLoadPersonFail === false
      const noResults = persons.length === 0

      return requestComplete && requestSucceeded && noResults
    },

    entrySettingTeams: {
      get() {
        const { entrySetting, isEntrySettingPrefilter, teams } = this
        const { network: networks = [] } = entrySetting || {}

        return networks.map((network) => {
          const { defaults: currentDefaults, billingCode, teamCode, teamId } = network
          const team = teams.find(((t) => t.billingCode === billingCode && t.teamId === teamId && t.teamCode === teamCode))
          const defaults = typeof currentDefaults === 'boolean' ? currentDefaults : isEntrySettingPrefilter

          return { ...network, ...team, defaults }
        })
      },
      set(newArray) {
        const { entrySetting, isEntrySettingPrefilter } = this
        const { network: teamSettings = [] } = entrySetting || {}

        if (!newArray?.length) {
          this.entrySetting.network = []

          return this.entrySetting.network
        }

        this.entrySetting.network = newArray.reduce((selectedTeams, teamOption) => {
          const { billingCode, teamCode, teamId } = teamOption
          const previouslySelected = teamSettings.find((nw) => nw.billingCode === billingCode && nw.teamId === teamId && nw.teamCode === teamCode)
          const { defaults = isEntrySettingPrefilter } = previouslySelected || {}
          const teamConfig = { defaults, billingCode, teamCode, teamId }

          selectedTeams.push(teamConfig)

          return selectedTeams
        }, [])

        return this.entrySetting.network
      },
    },
    currentNetworks: {
      get() {
        const { entry, organizations } = this
        const { network: networks = [] } = entry || {}

        return networks.map((network) => {
          const { organization: networkOrg } = network
          const { code: billingCode } = networkOrg
          const organization = organizations.find(this.findByBillingCode(billingCode))

          return { ...networkOrg, ...organization }
        })
      },
      set(newArray) {
        const { entry } = this
        const { network: networks = [] } = entry || {}

        if (!newArray?.length) {
          this.entry.network = []

          return this.entry.network
        }

        this.entry.network = newArray.reduce((userNetworks, network) => {
          const { billingCode } = network
          const previouslySelected = networks.find((nw) => nw.organization.code === billingCode) || {}
          const { team } = previouslySelected
          const networkAndTeams = this.makeNetworkConfig(network, team)

          userNetworks.push(networkAndTeams)

          return userNetworks
        }, [])

        return this.entry.network
      },
    },
    optionsTeams() {
      const { entry, organizations } = this
      const { network: networks = [] } = entry || {}
      const selectedBillingCodes = networks.map((network) => network.organization.code)

      return organizations
        .filter((org) => selectedBillingCodes.includes(org.billingCode))
        .reduce((teamsArray, org) => teamsArray.concat(org.teams), [])
    },
    currentTeams: {
      get() {
        const { entry, organizations = [] } = this
        const { network: networks = [] } = entry || {}

        return networks.reduce((teamsArray, network) => {
          const { organization: networkOrg, team: networkTeams = [] } = network
          const { code: billingCode } = networkOrg || {}
          const organization = organizations.find(this.findByBillingCode(billingCode))
          const { teams: allTeams = [] } = organization || {}
          const selectedTeams = networkTeams.map((team) => {
            const { code: teamCode, id: teamId } = team
            return allTeams.find((t) => String(t.teamId) === String(teamId) && t.teamCode === teamCode)
          })

          return teamsArray.concat(selectedTeams)
        }, [])
      },
      set(newTeamsArray) {
        const { currentNetworks } = this
        const teamsByBillingCode = newTeamsArray.reduce((groups, team) => {
          const { billingCode } = team

          if (!groups[billingCode]) {
            groups[billingCode] = []
          }

          groups[billingCode].push(team)

          return groups
        }, {})

        this.entry.network = currentNetworks.reduce((userNetworks, org) => {
          const { billingCode } = org
          const teams = teamsByBillingCode[billingCode]
          const networkAndTeams = this.makeNetworkConfig(org, teams)

          userNetworks.push(networkAndTeams)

          return userNetworks
        }, [])

        return this.entry.network
      },
    },
    rulesEmail() {
      const required = (value) => !!value || 'Required'
      const structured = (value) => /.+@.+/.test(value) || 'Invalid Email'

      return [required, structured]
    },
    rulesRequired() {
      return [(value) => !!value || 'Required']
    },
    entryDateText() {
      const { entry: { access: { expires } } } = this
      return dates.getFormattedDate(expires, 'dddd, MMMM DD, YYYY')
    },
    advancedSettings() {
      return this.entry?.settings?.filters.filter((setting) => typeof setting.name === 'string' && setting.name.trim().length > 0)
    },
    defaultAdvancedSetting() {
      return {
        name: undefined,
        network: [],
        parameters: [],
        reports: ['discrepancy', 'pacing'],
      }
    },
    allPropertyOptions() {
      const columns = this.$store.state.delivery?.columns
      const options = columns.map((column) => ({ text: column.columnName, value: column.key }))
      return _.sortDscByCaseInsensitive(options, 'text')
    },
    allOperatorOptions() {
      return _.sortDscByCaseInsensitive([...operators.numerical, ...operators.textual, ...operators.boolean], 'value')
    },
  },
  watch: {
    selected(newValue) {
      if (newValue) {
        // do something
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.personForm.validate()
    },
    reset() {
      return this.$refs.personForm.reset()
    },
    resetValidation() {
      return this.$refs.personForm.resetValidation()
    },
    close(action) {
      if (['cancel', 'delete'].indexOf(action) === -1) this.resetValidation()
      this.$store.commit('internal/isPersonModalVisible', false)
    },

    validateSetting() {
      return this.$refs.settingForm.validate()
    },
    resetSetting() {
      return this.$refs.settingForm.reset()
    },
    resetValidationSetting() {
      return this.$refs.settingForm.resetValidation()
    },
    closeSettingForm() {
      this.showSettingForm = false
      this.resetValidationSetting()
    },
    saveSettingForm() {
      const { entry: { settings: { filters } }, entrySetting } = this

      if (Array.isArray(filters)) {
        this.entry.settings.filters.push(entrySetting)
      } else {
        this.entry.settings.filters = [entrySetting]
      }

      this.showSettingForm = false
    },
    resetSettingForm() {
      this.entrySetting = this.selectedSetting
    },
    cancelSettingForm() {
      this.resetSettingForm()
      this.closeSettingForm()
    },
    openSettingForm(setting) {
      this.entrySetting = { ...setting }
      this.selectedSetting = { ...setting }

      this.showSettingForm = true
    },

    openSettingFormCreate() {
      const setting = this.defaultAdvancedSetting
      this.openSettingForm(setting)
    },
    openSettingFormUpdate(settingIndex) {
      const setting = this.entry.settings.filters[settingIndex]
      this.openSettingForm(setting)
    },

    deleteSettingParameters(columnKey, valueIndex) {
      this.entrySetting.parameters[columnKey].splice(valueIndex, 1)
    },
    saveSettingParameters() {
      const { key, operator, value } = this.entrySettingParameter

      if (!Array.isArray(this.entrySetting.parameters[key])) {
        this.entrySetting.parameters[key] = [{ operator, value }]
      } else {
        this.entrySetting.parameters[key].push({ operator, value })
      }

      this.entrySettingParameter = { key, operator, value: undefined }
    },

    getOrgAndTeamName(network) {
      const orgTeam = this.teams.find((team) => team.billingCode === network.billingCode && team.teamId === network.teamId && team.teamCode === network.teamCode)
      return orgTeam?.teamName || 'Unknown Team Name'
    },

    async submitPersonAction() {
      const { action, entry, isValidPersonForm } = this
      const requiresValidation = ['create', 'update'].includes(action)
      const validates = () => requiresValidation && isValidPersonForm
      const valid = !requiresValidation || validates()
      const { commit, dispatch } = this.$store

      if (!valid) {
        const message = 'Invalid Form'

        commit('tools/snackbar', true)
        commit('tools/snack', { message, status: 'error' })

        return false
      }

      entry.action_type = action.toUpperCase()

      const response = await dispatch('internal/submitPersonAction', entry)
      const { code, error } = response || {}

      if (error || code || !response) {
        const message = error?.message || response?.message || response?.code || 'Unknown API Error'

        commit('tools/snackbar', true)
        commit('tools/snack', { message, status: 'error' })

        return error || { error: true, message }
      }

      const { access, network } = response
      const isSuccessResponse = typeof access === 'object' && typeof network === 'object'
      const status = isSuccessResponse ? 'success' : 'error'
      const message = isSuccessResponse ? this.successMessage : this.errorMessage

      if (isSuccessResponse) {
        this.close(action)
      }

      commit('tools/snackbar', true)
      commit('tools/snack', { message, status })

      return response
    },
    cancelAction() {
      const { selected } = this

      this.$store.commit('internal/action', 'read')
      this.$store.commit('internal/entry', Object.create(selected))

      this.close('cancel')
    },

    findByBillingCode(billingCode) {
      return (nw) => nw.billingCode === billingCode
    },

    mapTeams(billingCode, teams) {
      return teams.map((team) => {
        const { teamCode, teamId } = team

        return {
          ...team,
          billingCode,
          code: teamCode,
          id: teamId,
        }
      })
    },
    makeNetworkConfig(selectedOrg, selectedTeams = []) {
      const { billingCode, clientId, networkCode, publisherId } = selectedOrg

      return {
        organization: {
          ...selectedOrg,
          id: publisherId || clientId,
          code: billingCode,
          networkCode,
        },
        team: this.mapTeams(billingCode, selectedTeams),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

::v-deep.v-card {
  border-radius: 6px;
  border-width: 0;
}

::v-deep.v-card__title {
  color: $color-monochrome-darkestblue;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.51px;
  line-height: 22px;
  justify-content: space-between;
}

::v-deep.v-card__text {
  padding: 0;
}

.v-card + .v-card {
  margin-top: 1ch;
}
</style>
